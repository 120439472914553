import React from 'react';
import {
  Stack,
  Typography,
  styled,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import BombIcon from 'assets/icons/bomb';

import { colors } from 'theme';
import ZoneIcon from '../../assets/icons/zone';

interface IProps {
  name: string
  type?: string
}

const KHUav = styled(Stack)`
  min-height: 20px;
  padding: 2px 6px;
  border-radius: 10px;
  background-color: ${colors.grayscale.b2};
`;

function UavTypeIconWithText({ name, type = '' }: IProps) {
  return (
    <KHUav flexDirection="row" alignItems="center" gap={1}>
      { type === 'recon' ? <VisibilityIcon fontSize="small" style={{ color: colors.grayscale.g2 }} /> : null }
      { type === 'strike' ? <RocketLaunchIcon fontSize="small" style={{ color: colors.grayscale.g2, width: '17px' }} /> : null }
      { type === 'ammunition' ? <BombIcon color={colors.grayscale.g2} /> : null }
      { type === 'zone' ? <ZoneIcon color={colors.grayscale.g2} /> : null }
      <Typography variant="body2">{name}</Typography>
    </KHUav>
  );
}

export default UavTypeIconWithText;
