import React from 'react';

interface IProps {
  width?: number;
  height?: number;
  fill?: string;
}

function SparkIcon({ width = 18, height = 18, fill = '#5F5F5F' }: IProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.00543 15.875L8.14151 11.6654L6.78156 13.5556L6.66761 10.9808L1.39528 14.7174L5.48718 9.76675L1.30472 10.2392L5.60059 8.01515L0.850342 7.62581L5.57313 6.77447L2.62586 4.65533L5.95573 5.01525L1.50391 0.511861L6.88434 3.77457L7.01743 1.10961L8.58277 3.33171L10.6409 0.125L10.6057 4.14477L13.6887 1.34371L11.9651 5.09376L17.1258 2.9133L12.7964 6.54746L17.1499 6.9453L12.9669 8.19432L16.6039 10.2346L12.5026 9.77135L15.643 12.8168L11.4428 11.0597L12.7394 14.3139L9.50996 10.8125L9.00543 15.875Z"
        fill={fill}
      />
    </svg>
  );
}

export default SparkIcon;
