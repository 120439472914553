import React from 'react';
import {
  Box, Button, Dialog, Divider, Stack, TextareaAutosize, Typography, useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { IMission } from 'types/mission';
import { colors } from 'theme';
import { useTranslation } from 'react-i18next';
import { missionStatus } from '../../../constants/mission';

interface IProps {
    open: boolean;
    mission: IMission;
    onClose: () => void;
    onSave: (status: string, report: string) => void;
    title: string;
    subtitle: string;
    suspendConfirmation?: boolean;
    status?: string;
}

function EndMissionConfirmation({
  open, mission, onClose, onSave, title, subtitle, suspendConfirmation = false, status = '',
}: IProps) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const [selectedStatus, setSelectedStatus] = React.useState<string | null>(status || null);
  const [report, setReport] = React.useState<string>('');

  return (
    <Dialog
      open={open}
      fullScreen={isMobile}
      maxWidth="lg"
      PaperProps={{
        sx: {
          background: colors.grayscale.b4,
        },
      }}
    >
      <Stack p={2} gap={3} minWidth="400px" height="100%">
        <Stack alignItems="flex-end">
          <CloseIcon onClick={onClose} />
        </Stack>
        <Typography color={colors.grayscale.g2}>{mission.name}</Typography>
        <Typography variant="h3">{title}</Typography>
        <Typography color={colors.grayscale.g2}>{subtitle}</Typography>
        {!suspendConfirmation && (
          <>
            <Button
              className="completed"
              variant={selectedStatus === missionStatus.COMPLETED ? 'contained' : undefined}
              sx={{ border: `2px solid ${colors.system.g1}`, color: colors.system.g1 }}
              onClick={() => setSelectedStatus(missionStatus.COMPLETED)}
            >
              {t(missionStatus.COMPLETED)}
            </Button>
            <Button
              color="error"
              className="not_completed"
              variant={selectedStatus === missionStatus.NOT_COMPLETED ? 'contained' : undefined}
              sx={{ border: `2px solid ${colors.system.red}` }}
              onClick={() => setSelectedStatus(missionStatus.NOT_COMPLETED)}
            >
              {t(missionStatus.NOT_COMPLETED)}
            </Button>
          </>
        )}
        <TextareaAutosize
          placeholder={t('add_report')}
          maxRows={10}
          onChange={(e) => setReport(e.target.value)}
          style={{
            backgroundColor: colors.grayscale.b4,
            border: '1px solid',
            borderRadius: '20px',
            padding: '10px',
            color: colors.grayscale.g2,
            minHeight: '150px',
          }}
        />
        <Box height="100%" />
        <Divider />
        <Button
          variant="contained"
          color="primary"
          disabled={!selectedStatus && !suspendConfirmation}
          onClick={() => onSave(selectedStatus as string, report)}
        >
          {t('save')}
        </Button>
        <Button variant="outlined" color="primary" onClick={onClose}>{t('cancel')}</Button>
      </Stack>
    </Dialog>
  );
}

export default EndMissionConfirmation;
