import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, CardContent, Grid, Stack, styled, TextField, Typography,
} from '@mui/material';

import { IMissionDto } from 'types/mission';
import { ITarget } from 'types/target';
import { ICrew } from 'types/crew';
import { IGetZone, INAI } from 'types/zone';
import { colors } from 'theme';
import DatePicker from 'components/datePicker';
import { dayjs } from 'services/dayjs';

import MissionTypeSelector from 'components/missionTypeSelector';
import Autocomplete from 'components/autocomplete';
import DroneIcon from 'assets/icons/drone';
import IconFrame from 'components/iconFrame';
import { getTextFieldErrorProps } from 'services/helpers';
import TargetCard from '../../targets/components/targetCard';

interface IProps {
  showTargetsSelect: boolean
  setShowTargetsSelect: () => void
  mission: IMissionDto
  setMission: (mission: IMissionDto) => void
  crews?: ICrew[]
  zones?: IGetZone[]
  onTargetClick: (target: ITarget) => void
  errors: any,
}

const FormCardContent = styled(CardContent)(() => ({
  padding: '32px',
  backgroundColor: `${colors.grayscale.b2}`,
  border: `1px solid ${colors.grayscale.b4}`,
  borderRadius: '24px',
}));

const AutocompleteStyled = styled((props: any) => <Autocomplete {...props} />)`
  .MuiInputBase-root {
    min-height: 45px;
  }
  .MuiOutlinedInput-notchedOutline {
    border: ${(props) => (props.error ? `2px solid ${colors.system.red}` : undefined)};
  }
  .MuiOutlinedInput-root {
    padding: 5px;
  }
  .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 7px 14px;
  }
  .MuiChip-root {
    height: 29px;
  }
  .MuiAutocomplete-popper {
    background-color: green;
  }
`;

const EmptyButton = styled(Button)`
  padding: 0;
  margin-top: 12px;
  color: ${colors.grayscale.w1};
  text-decoration: underline;
  border: none;
  background-color: transparent;
  &:hover {
    background-color: transparent;
  }
`;

function MissionForm(
  {
    setShowTargetsSelect,
    showTargetsSelect,
    onTargetClick,
    setMission,
    mission,
    crews = [],
    zones = [],
    errors,
  }: IProps,
) {
  const { t } = useTranslation();
  const [currentZoneNaisList, setCurrentZoneNaisList] = React.useState<INAI[]>([]);

  const updateMission = (field: string, value: any) => {
    setMission({ ...mission, [field]: value });
  };

  useEffect(() => {
    const currentZoneNais = mission.zones
      ?.map(({ name, nais }) => nais
        ?.map((n) => ({ ...n, zoneName: name })) || []).flat();
    setCurrentZoneNaisList(currentZoneNais || []);
  }, [mission.zones]);

  return (
    <FormCardContent>
      <Grid container spacing={3} alignItems="center">
        <Grid item md={showTargetsSelect ? 12 : 8} xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} alignContent="center">
              <Typography variant="h3">{t('type')}</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <MissionTypeSelector
                selectedType={mission?.type}
                setSelectedType={(type) => updateMission('type', type)}
                error={errors.type}
              />
            </Grid>
            <Grid item xs={12} md={4} alignContent="center">
              <Typography variant="h3">{t('mission_name')}</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                placeholder={t('enter_name')}
                value={mission?.name}
                onChange={(e) => updateMission('name', e.target.value)}
                size="small"
                {...getTextFieldErrorProps(errors, 'name', t)}
              />
            </Grid>
            { mission?.type === 'strike' && (
              <>
                <Grid item xs={12} md={4} alignContent="center">
                  <Typography variant="h3">{t('targets')}</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Stack gap={1}>
                    {mission.target.map((target) => (
                      <TargetCard
                        key={target.id}
                        target={target}
                        showStatus={false}
                        selectTarget={() => {}}
                        onClose={onTargetClick}
                        showCloseIcon
                      />
                    ))}
                  </Stack>
                  {mission.target.length > 0 ? (
                    <EmptyButton onClick={() => setShowTargetsSelect()}>Вибрати ще</EmptyButton>
                  ) : (
                    <>
                      <Button variant={showTargetsSelect ? 'contained' : 'outlined'} color="primary" size="large" onClick={() => setShowTargetsSelect()}>
                        {t('select_targets')}
                      </Button>
                      {errors.target && <Typography variant="body2" color="error" ml={2}>{t(errors.target)}</Typography>}
                    </>
                  )}
                </Grid>
              </>
            ) }
            { mission?.type === 'recon' && (
              <>
                <Grid item xs={12} md={4} alignContent="center">
                  <Typography variant="h3">{t('work_area')}</Typography>
                </Grid>
                <Grid item xs={12} md={8} alignContent="center">
                  <TextField
                    fullWidth
                    placeholder={t('work_area')}
                    value={mission?.work_area || ''}
                    onChange={(e) => updateMission('work_area', e.target.value)}
                    size="small"
                    {...getTextFieldErrorProps(errors, 'work_area', t)}
                  />
                </Grid>
                <Grid item xs={12} md={4} alignContent="center">
                  <Typography variant="h3">{t('work_zones')}</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <AutocompleteStyled
                    multiple
                    id="mission-zone"
                    options={zones}
                    getOptionLabel={(option: IGetZone) => option.name || ''}
                    onChange={(e: any, value: IGetZone[]) => {
                      setMission({ ...mission, zones: value });
                    }}
                    value={zones?.filter((zone) => (
                      mission.zones?.find((zn) => zn.id === zone.id)
                    ))}
                    renderInput={(params: any) => (
                      <TextField
                        placeholder={t('choose_zones')}
                        {...params}
                        {...getTextFieldErrorProps(errors, 'ammo', t)}
                      />
                    )}
                  />
                  {errors.zones && <Typography variant="body2" color="error" ml={2}>{t(errors.zones)}</Typography>}
                </Grid>
                <Grid item xs={12} md={4} alignContent="center">
                  <Typography variant="h3">{t('work_zones_nais')}</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <AutocompleteStyled
                    multiple
                    id="mission-zone-nais"
                    options={currentZoneNaisList}
                    getOptionLabel={(option: INAI) => (option.name || '') + (option.zoneName ? ` • ${option.zoneName}` : '')}
                    onChange={(e: any, value: INAI[]) => {
                      updateMission('nais', value);
                    }}
                    value={(mission.nais || []).filter(({ id: missionNaiId }) => currentZoneNaisList
                      .some(({ id: zoneNaiId }) => missionNaiId === zoneNaiId))}
                    isOptionEqualToValue={(option: ICrew, value: ICrew) => option.id === value.id}
                    renderInput={(params: any) => (
                      <TextField
                        placeholder={t('choose_zone_nais')}
                        {...params}
                      />
                    )}
                  />
                  {errors.nais && <Typography variant="body2" color="error" ml={2}>{t(errors.nais)}</Typography>}
                </Grid>
              </>
            ) }
            <Grid item xs={12} md={4} alignContent="center">
              <Typography variant="h3">{t('due_date')}</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <DatePicker
                value={dayjs(mission?.due_date) || dayjs()}
                onChange={(value) => updateMission('due_date', value)}
                time
              />
            </Grid>
            <Grid item xs={12} md={4} alignContent="center">
              <Typography variant="h3">{t('crew')}</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <AutocompleteStyled
                id="mission-crew"
                options={crews}
                getOptionLabel={(option: any) => {
                  const selectedCrew = crews?.find((cr) => cr.id === option.id);
                  return `${selectedCrew?.name} • ${selectedCrew?.uav[0].name}`;
                }}
                onChange={(e: Event, value: ICrew) => {
                  updateMission('crew', value);
                }}
                value={mission.crew}
                renderOption={(props: any, option: any) => (
                  <li {...props}>
                    <Stack flexDirection="row" alignItems="center" gap="5px">
                      <IconFrame backgroundColor="#454545"><DroneIcon width="13px" height="13px" /></IconFrame>
                      {option.name} • {option.uav[0].name}
                    </Stack>
                  </li>
                )}
                isOptionEqualToValue={(option: ICrew, value: ICrew) => option.id === value.id}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    placeholder={t('select_crew')}
                  />
                )}
              />
              {errors.crew && <Typography variant="body2" color="error" ml={2}>{t(errors.crew)}</Typography>}
            </Grid>
            <Grid item xs={12} md={4} alignContent="center">
              <Typography variant="h3">{t('description')}</Typography>
            </Grid>
            <Grid item xs={12} md={8} alignContent="center">
              <TextField
                fullWidth
                placeholder={t('mission_description')}
                value={mission?.description}
                onChange={(e) => updateMission('description', e.target.value)}
                size="small"
                {...getTextFieldErrorProps(errors, 'description', t)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormCardContent>
  );
}

export default MissionForm;
