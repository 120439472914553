import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { Button, Stack, Typography, styled } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';

import { urls } from 'router';
import api, { apiURLs } from 'services/api';
import { ICreateZoneDto, IZone, INAI } from 'types/zone';
import defaultZone from 'defaults/zone';

import GroupBy from 'components/groupBy';
import NAIMap from '../components/naiMap';
import NAICard from '../components/naiCard';
import RelatedMisssions from '../components/relatedMissions';

const groupByItems = ['zone-details', 'related-missions'];

const StyledButton = styled(Button)`
  color: white;
  width: 136px;
  hight: 36px;
  padding: 0;
  font-weight: 400;
  border: none;
`;

const StyledEditButton = styled(Button)`
  color: white;
  width: 136px;
  hight: 36px;
  font-weight: 400;
  border-color: #2f5623;
`;

function DetailsZone() {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const groupParam = groupByItems[0];
  const [groupedBy, setGroupedBy] = useState(groupParam);
  const [zone, setZone] = useState<ICreateZoneDto | IZone | undefined>(defaultZone);

  /* eslint-disable */
  const { data: zoneDetails, refetch: refetchZoneDetails } = useQuery({
    queryKey: ['zone-details'],
    queryFn: (): Promise<IZone> =>
      api
        .get(apiURLs.zones.details(id))
        .then((res) => res.data)
        .catch((err) => toast.error(err.response.data?.message)),
  });

  useEffect(() => setZone(zoneDetails), [zoneDetails]);

  return (
    <>
      <Stack direction="row" justifyContent="start">
        <StyledButton onClick={() => navigate(urls.zones.landing)} variant="text" startIcon={<ArrowBackIcon />}>
          {t('zone-details')}
        </StyledButton>
      </Stack>

      <Stack direction="row" gap={5} alignItems="center" justifyContent="space-between" p={2} pl={0} pr={0}>
        <Typography variant="h1">{zone?.name}</Typography>
        <StyledEditButton onClick={() => navigate(urls.zones.edit(id))} variant={'text'} size="medium">
          <EditIcon fontSize="small" style={{ paddingRight: '3px', color: 'white' }} />
          {t('edit')}
        </StyledEditButton>
      </Stack>

      <Typography style={{ marginBottom: '20px', color: 'white' }}>{zone?.description}</Typography>

      <GroupBy items={groupByItems} selected={groupedBy} onChange={(group: string) => setGroupedBy(group)} hideLabel />

      {groupedBy === 'zone-details' && (
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <NAIMap nais={zone?.nais as INAI[]} />
          <NAICard nais={zone?.nais as INAI[]} zoneId={(zone as IZone)?.id || ''} refetchDetails={refetchZoneDetails} />
        </Stack>
      )}
      {groupedBy === 'related-missions' && (
        <Stack>
          {/* TODO props */}
          <RelatedMisssions />
        </Stack>
      )}
    </>
  );
}

export default DetailsZone;
