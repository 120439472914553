import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { urls } from 'router';
import { Container } from '@mui/material';
import * as yup from 'yup';
import { ValidationError } from 'yup';
import api, { apiURLs } from 'services/api';
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import FakeModal from 'components/fakeModal';
import { IGetUAVResponse } from 'types/uav';
import { IGetUsersResponse } from 'features/admin/users/services/model';
import { getValidationErrors } from 'services/helpers';
import { AmmunitionRes } from 'features/admin/ammunition/services/model';
import {
  ICreateCrewDto, ICrew, IGetLocationResponse, ILocation,
} from 'types/crew';
import defaultCrew from 'defaults/crew';
import { BaseFilters } from 'services/model';
import CrewForm from '../components/crewForm';

interface IProps {
  currentCrew?: ICrew
  crewId?: string
}

const schema = yup.object({
  name: yup.string().required('name_required'),
  uav: yup.array().min(1, 'uav_required'),
  members: yup.array().min(1, 'members_required'),
  location: yup.mixed().required('location_required').test(
    'is-valid-location',
    'location_required',
    (value) => {
      if (!value) return false;
      return yup.object({
        name: yup.string().required('location_required'),
      }).isValidSync(value);
    },
  ),
}).required();

const defaultFilter: BaseFilters = {
  page: 1,
  limit: 100,
};

const usersFilter: BaseFilters = {
  page: 1,
  limit: 100,
  role: ['crew_member'],
};

function CreateCrew({ currentCrew = defaultCrew, crewId = '' }: IProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [crew, setCrew] = React.useState<ICrew | undefined>(defaultCrew);
  const [locations, setLocations] = React.useState<ILocation[]>();
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => setCrew(currentCrew), [currentCrew]);

  const {
    data: uavData,
  } = useQuery({
    queryKey: ['uav-list'],
    queryFn: (): Promise<IGetUAVResponse> => api.get(apiURLs.admin.uavs.list(defaultFilter))
      .then((res) => res.data)
      .catch((err) => toast.error(err.response.data?.message)),
  });

  const {
    data: usersData,
  } = useQuery({
    queryKey: ['users-list'],
    queryFn: (): Promise<IGetUsersResponse> => api.get(`${apiURLs.admin.users.list(usersFilter)}`)
      .then((res) => res.data)
      .catch((err) => toast.error(err.response.data?.message)),
  });

  const {
    data: locationsData,
  } = useQuery({
    queryKey: ['locations-list'],
    queryFn: (): Promise<IGetLocationResponse> => (
      api.get(apiURLs.admin.location.list(defaultFilter))
    )
      .then((res) => res.data)
      .catch((err) => toast.error(err.response.data?.message)),
  });

  React.useEffect(() => {
    if (locationsData) {
      setLocations(locationsData.results);
    }
  }, [locationsData]);

  const {
    data: ammunition,
  } = useQuery({
    queryKey: ['ammunition-list'],
    queryFn: (): Promise<AmmunitionRes> => api.get(apiURLs.admin.ammunition.list(defaultFilter))
      .then((res) => res.data)
      .catch((err) => toast.error(err.response.data?.message)),
  });

  const mutationCb = (res: any, reset: any) => {
    reset();
    navigate(urls.crews.landing);
    toast.success(t('crew_created'));
    return res.data;
  };

  const {
    mutate: submit, reset,
  } = useMutation({
    mutationKey: ['submit-crew'],
    mutationFn: (c: ICreateCrewDto) => (crewId
      ? api.patch(apiURLs.crews.updateCrew(crewId), c)
        .then((res) => {
          mutationCb(res, reset);
        })
        .catch((err) => toast.error(t(err.response.data?.errorCode)))
      : api.post(apiURLs.crews.create, c)
        .then((res) => {
          mutationCb(res, reset);
        })
        .catch((err) => toast.error(t(err.response.data?.errorCode)))
    ),
  });

  const transformAndSubmit = () => {
    if (crew) {
      submit({
        ...crew,
        // @ts-ignore
        uav: crew.uav?.map((cru) => cru.id),
        ammo: crew.ammo?.map((cram) => cram.id),
        location: crew.location?.id,
        members: crew.members?.map((crmem) => crmem.id),
        description: '',
      });
    }
  };

  const validateAndSubmit = () => {
    schema.validate(crew, { abortEarly: false }).then(() => {
      setErrors({});
      transformAndSubmit();
    }).catch((err: ValidationError) => {
      setErrors(getValidationErrors(err));
    });
  };

  return (
    <Container maxWidth="xl">
      <FakeModal
        title={t('createCrew')}
        cancel={() => navigate(urls.crews.landing)}
        onSubmit={() => validateAndSubmit()}
      >
        <CrewForm
          // @ts-ignore
          crew={crew}
          setCrew={setCrew}
          uavs={uavData?.results || []}
          users={usersData?.results || []}
          locations={locations}
          ammunitions={ammunition?.results || []}
          errors={errors}
        />
      </FakeModal>
    </Container>
  );
}

export default CreateCrew;
