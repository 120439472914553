import React from 'react';

interface IProps {
  width?: number;
  height?: number;
  fill?: string;
}

function CameraIcon({ width = 18, height = 18, fill = '#5F5F5F' }: IProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.0001 12.1496C10.3256 12.1496 11.4001 11.0751 11.4001 9.74961C11.4001 8.42413 10.3256 7.34961 9.0001 7.34961C7.67461 7.34961 6.6001 8.42413 6.6001 9.74961C6.6001 11.0751 7.67461 12.1496 9.0001 12.1496Z"
        fill={fill}
      />
      <path
        d="M6.75 2.25L5.3775 3.75H3C2.175 3.75 1.5 4.425 1.5 5.25V14.25C1.5 15.075 2.175 15.75 3 15.75H15C15.825 15.75 16.5 15.075 16.5 14.25V5.25C16.5 4.425 15.825 3.75 15 3.75H12.6225L11.25 2.25H6.75ZM9 13.5C6.93 13.5 5.25 11.82 5.25 9.75C5.25 7.68 6.93 6 9 6C11.07 6 12.75 7.68 12.75 9.75C12.75 11.82 11.07 13.5 9 13.5Z"
        fill={fill}
      />
    </svg>
  );
}

export default CameraIcon;
