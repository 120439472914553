import React from 'react';
import { Container, Stack, Typography } from '@mui/material';

import { colors } from 'theme';
import Logo from './logo';

interface IProps {
  bgColor?: string;
}

function Footer({ bgColor = colors.grayscale.b3 }: IProps) {
  return (
    <Stack
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        borderTop: `1px solid ${colors.grayscale.b4}`,
        backgroundColor: bgColor,
        width: '100%',
      }}
    >
      <Container maxWidth="xl">
        <Stack direction="row" p={2} pl={0} pr={0} justifyContent="space-between" alignItems="center">
          <Logo width={32} height={32} fill={colors.grayscale.g2} />
          <Typography variant="body2">Xартія, {new Date().getFullYear()} ©</Typography>
        </Stack>
      </Container>
    </Stack>
  );
}

export default Footer;
