import React from 'react';

interface IProps {
  fill?: string;
  width?: number;
  height?: number;
}

function TargetIcon({ width = 18, height = 18, fill = '#5F5F5F' }: IProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.31482 16.5381V15.1682C6.88788 15.0084 5.66357 14.4177 4.64188 13.396C3.62019 12.3743 3.02944 11.15 2.86962 9.72302H1.49976V8.35315H2.86962C3.02944 6.92621 3.62019 5.7019 4.64188 4.68021C5.66357 3.65852 6.88788 3.06777 8.31482 2.90795V1.53809H9.68469V2.90795C11.1116 3.06777 12.3359 3.65852 13.3576 4.68021C14.3793 5.7019 14.9701 6.92621 15.1299 8.35315H16.4998V9.72302H15.1299C14.9701 11.15 14.3793 12.3743 13.3576 13.396C12.3359 14.4177 11.1116 15.0084 9.68469 15.1682V16.5381H8.31482ZM8.99976 13.8326C10.324 13.8326 11.4541 13.3646 12.3902 12.4285C13.3262 11.4924 13.7943 10.3623 13.7943 9.03809C13.7943 7.71388 13.3262 6.58375 12.3902 5.64768C11.4541 4.7116 10.324 4.24357 8.99976 4.24357C7.67555 4.24357 6.54542 4.7116 5.60934 5.64768C4.67327 6.58375 4.20524 7.71388 4.20524 9.03809C4.20524 10.3623 4.67327 11.4924 5.60934 12.4285C6.54542 13.3646 7.67555 13.8326 8.99976 13.8326Z"
        fill={fill}
      />
    </svg>
  );
}

export default TargetIcon;
