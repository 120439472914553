import React from 'react';
import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import debounce from 'lodash/debounce';

interface Props {
  // eslint-disable-next-line no-unused-vars
  onSearch: (text: string) => void;
  defaultValue?: string | null;
}

function Search({ onSearch, defaultValue = '' }: Props) {
  const debouncedOnSearch = React.useCallback(
    debounce((text: string) => onSearch(text), 500),
    [onSearch],
  );

  return (
    <TextField
      size="small"
      label="Search"
      variant="outlined"
      defaultValue={defaultValue}
      sx={{
        input: {
          padding: '11px 14px',
        },
      }}
      onChange={(e) => debouncedOnSearch(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      autoComplete="off"
    />
  );
}

export default Search;
