import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion, AccordionDetails, AccordionSummary, Button, Grid, Stack, styled, Typography,
} from '@mui/material';

import { ITarget } from 'types/target';
import { colors } from 'theme';
import TargetSign from '../../targets/components/targetSign';
import TargetDetailsInfo from '../../targets/components/targetDetailsInfo';
import TargetImagesGallery from '../../targets/components/targetImagesGallery';
import getDistanceToTarget from '../../../services/coordinates';
import { DELTA_LAYER_ID } from '../../../constants/target';

interface IProps {
  target: ITarget
  locationDistance: number
  reconTarget?: boolean
}

interface IToggleButton {
  isExpanded: boolean
  toggleAccordion: () => void
  fullWidth: boolean
  labelShow: string
  labelHide: string
}

const TargetAccordion = styled(Accordion)`
  flex-direction: column;
  margin-bottom: 20px;
  border: 1px solid ${colors.grayscale.b5};
  background-color: ${colors.grayscale.b3};
  .MuiAccordionSummary-root {
    user-select: auto;
  }
  .MuiAccordionSummary-root:hover {
    cursor: default;
  }
  .MuiAccordionSummary-root:hover:not(.Mui-disabled) {
    cursor: default;
  }
`;

const ExpandButton = styled(Button)`
  color: ${colors.grayscale.w1};
  background-color: ${colors.grayscale.b5};
  border: 2px solid ${colors.grayscale.b5};
  &:hover {
    border: 2px solid ${colors.grayscale.g1};
    background-color: ${colors.grayscale.g1};
  };
  &:active {
    color: ${colors.grayscale.g2};
    border: 2px solid ${colors.grayscale.b5};
    background-color: ${colors.grayscale.b5};
  }
`;

function ToggleDetailsButton(
  {
    isExpanded,
    toggleAccordion,
    fullWidth,
    labelShow,
    labelHide,
  }: IToggleButton,
) {
  return (
    <ExpandButton fullWidth={fullWidth} onClick={() => toggleAccordion()}>
      {isExpanded ? labelHide : labelShow}
    </ExpandButton>
  );
}

function TargetCardAccordion({ target, locationDistance, reconTarget = false }: IProps) {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <TargetAccordion
      expanded={isExpanded}
      disableGutters
    >
      <AccordionSummary>
        <Stack flexGrow={1}>
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center" mb={3}>
            <Stack>
              <Typography variant="body2" mb={1}>{t('target_details_header')}</Typography>
              <Typography variant="h3">{target.name || target.app6d_type}</Typography>
            </Stack>
            <TargetSign sidc={target.app6d_sidc} width={46} height={46} />
          </Stack>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={6} mb={2}>
              <Typography variant="body2" mb={1}>{t('type')}</Typography>
              <Typography variant="body1">{target.app6d_type}</Typography>
            </Grid>
            <Grid item xs={6} mb={2}>
              <Typography variant="body2" mb={1}>{t('quantity')}</Typography>
              <Typography variant="body1">{target.quantity || 1}</Typography>
            </Grid>
            <Grid item xs={6} mb={2}>
              <Typography variant="body2" mb={1}>{t('coordinates')}</Typography>
              <Typography variant="body1">{target.coordinates || '-'}</Typography>
            </Grid>
            <Grid item xs={6} mb={2}>
              <Typography variant="body2" mb={1}>{t('target_distance')}</Typography>
              {getDistanceToTarget(locationDistance, target.coordinates)} км
            </Grid>
            <Grid item xs={12} mb={2}>
              <Typography variant="body2" mb={1}>{t('records')}</Typography>
              <Typography variant="body1">{target.comments || '-'}</Typography>
            </Grid>
          </Grid>
          <ToggleDetailsButton
            toggleAccordion={() => setIsExpanded(!isExpanded)}
            isExpanded={isExpanded}
            labelShow={t('show_details')}
            labelHide={t('hide_details')}
            fullWidth
          />
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <TargetDetailsInfo target={target} reconTarget={reconTarget} short />
        <TargetImagesGallery images={target.images} />
        <Stack mb={4}>
          <Button
            variant="outlined"
            color="secondary"
            component="a"
            href={`https://delta.mil.gov.ua/monitor/map?layerId=${DELTA_LAYER_ID}&objectId=${target.uuid}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('open_in_delta')}
          </Button>
        </Stack>
      </AccordionDetails>
    </TargetAccordion>
  );
}

export default TargetCardAccordion;
