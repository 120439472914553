import React from 'react';

interface IProps {
  width?: number;
  height?: number;
}

function Camp({ width = 23, height = 23 }: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} version="1.2" baseProfile="tiny" viewBox="26 16 148 158">
      <text x="192" y="70" textAnchor="start" fontSize="60" fontFamily="Arial" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round" stroke="#ffffff" fill="none" />
      <path d="M85,40 85,18 115,18 115,40 100,24 Z" strokeWidth="44" strokeLinecap="round" strokeLinejoin="round" stroke="none" fill="none" />
      <path d="M 100,28 L172,100 100,172 28,100 100,28 Z Z" strokeWidth="44" strokeLinecap="round" strokeLinejoin="round" stroke="none" fill="none" />
      <path d="M 100,28 L172,100 100,172 28,100 100,28 Z" strokeWidth="4" stroke="black" fill="rgb(255,128,128)" fillOpacity="1" />
      <path d="m 65,124.4 10,-37 25,-10 25,10 10,37 z" strokeWidth="4" stroke="black" fill="none" />
      <path d="M85,40 85,18 115,18 115,40 100,24 Z" strokeWidth="4" stroke="black" fill="black" />
      <text x="192" y="70" textAnchor="start" fontSize="60" fontFamily="Arial" strokeWidth="4" stroke="none" fill="#000000" />
    </svg>
  );
}

export default Camp;
