import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

/* eslint-disable-next-line */
import { Button, Stack, Typography, styled, Card } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { colors } from 'theme';
import api, { apiURLs } from 'services/api';
import { INAI } from 'types/zone';

import NAIDrawer from './naiDrawer';

interface IProps {
  nais: INAI[];
  zoneId: string;
  refetchDetails: () => void;
}

const NaiCard = styled(Card)(() => ({
  marginBottom: '8px',
  padding: '10px 16px',
  border: `1px solid ${colors.grayscale.b5}`,
  backgroundColor: colors.grayscale.b3,
  width: '30vw',
  height: 'auto',
  marginTop: '10px',
}));

const IconButton = styled(Button)`
  padding: 2px 2px;
  color: ${colors.grayscale.g2};
  min-width: auto;
  border: none;
  &:hover {
    background-color: transparent;
  }
`;

function NAICard({ nais, zoneId, refetchDetails }: IProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [selectedItem, setSelectedItem] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [mode, setMode] = useState('create');

  const handleButtonClick = (item: any, m: string) => {
    setMode(m);
    setSelectedItem(item);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setSelectedItem(null);
  };

  const { mutate: submitNAI, reset: resetSubmitNAI } = useMutation({
    mutationKey: ['submit-nai'],
    mutationFn: (nai: any) =>
      /* eslint-disable-next-line */
      api
        .post(apiURLs.nais.create, { name: nai.name, zoneId, coordinates: nai.coordinates })
        .then(() => {
          resetSubmitNAI();
          refetchDetails();
          toast.success(t('nai_added'));
          setSelectedItem(null);
        })
        .catch(() => setSelectedItem(null)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['zone-list'] });
    },
  });

  const { mutate: updateNAI, reset: resetUpdateNAI } = useMutation({
    mutationKey: ['update-nai'],
    mutationFn: ({ id, data }: { id: any; data: any }) =>
      /* eslint-disable-next-line */
      api
        .patch(apiURLs.nais.update(id), data)
        .then(() => {
          resetUpdateNAI();
          refetchDetails();
          toast.success(t('nai_updated'));
        })
        .catch((err) => toast.error(err.response.data?.errorCode)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['zone-list'] });
    },
  });

  const { mutate: deleteNAI } = useMutation({
    mutationKey: ['delete-nai'],
    mutationFn: (id: string) =>
      /* eslint-disable-next-line */
      api
        .delete(apiURLs.nais.delete(id))
        .then(() => {
          refetchDetails();
          toast.success(t('nai_deleted'));
        })
        .catch((err) => toast.error(err.response.data?.errorCode)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['zone-list'] });
    },
  });

  const saveNai = (nai: any) => {
    setDrawerOpen(false);
    if (mode === 'create') {
      submitNAI({ ...nai, zoneId });
    }
    if (mode === 'edit') {
      updateNAI({ id: nai.id, data: nai });
    }
  };

  return (
    <>
      <NaiCard>
        <Stack direction="row" sx={{ justifyContent: 'space-between', paddingBottom: '12px' }}>
          <Typography>NAI {nais?.length}</Typography>

          <IconButton size="small">
            <AddIcon onClick={() => handleButtonClick(null, 'create')} />
          </IconButton>
        </Stack>

        {nais?.map((nai) => (
          <Stack key={nai.id} flexDirection="row" gap={3} justifyContent="space-between" alignItems="center">
            <Typography variant="body1">{nai?.name} </Typography>
            <Stack flexDirection="row" alignItems="center" gap="10px">
              <IconButton>
                <EditIcon onClick={() => handleButtonClick(nai, 'edit')} fontSize="small" />
              </IconButton>
              <IconButton>
                <DeleteIcon onClick={() => deleteNAI(nai.id)} fontSize="small" />
              </IconButton>
            </Stack>
          </Stack>
        ))}
      </NaiCard>
      {/* eslint-disable-next-line */}
      <NAIDrawer open={drawerOpen} nai={selectedItem} onClose={handleCloseDrawer} saveNai={saveNai} onMode={mode} />
    </>
  );
}

export default NAICard;
