import React from 'react';

interface IProps {
  color?: string;
}

function ZoneIcon({ color = 'currentColor' }: IProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 14C15.5 14.8284 14.8284 15.5 14 15.5H2C1.17157 15.5 0.5 14.8284 0.5 14V2C0.5 1.17157 1.17157 0.5 2 0.5H14C14.8284 0.5 15.5 1.17157 15.5 2V14ZM11.7515 2H14V4.24845L11.7515 2ZM10.6909 2H7.25155L14 8.74845V5.30911L10.6909 2ZM2 6.80911V10.2485L5.75154 14H9.19089L2 6.80911ZM10.2515 14L2 5.74845V2.3107L13.6893 14H10.2515ZM14 13.25V9.80911L6.19089 2H2.74997L14 13.25ZM4.69088 14L2 11.3091V14H4.69088Z"
        fill={color}
      />
    </svg>
  );
}

export default ZoneIcon;
