import React from 'react';

function RelatedMissions() {
  return (
    <div>
      <h1>RelatedMissions</h1>
    </div>
  );
}

export default RelatedMissions;
