import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

import {
  Box, Button, Card, IconButton, Stack, Typography,
} from '@mui/material';

import './fakeModal.css';

interface FakeModalProps {
  title: string;
  children: React.ReactNode;
  onSubmit?: (data: any) => any;
  cancel: () => void;
  isLoading?: boolean;
  hideControls?: boolean;
  logo?: string
}

function FakeModal(props: FakeModalProps) {
  const {
    title,
    children,
    onSubmit = () => {},
    isLoading,
    cancel,
    hideControls = false,
    logo = '',
  } = props;
  const { t } = useTranslation();

  return (
    <>
      <Card className="header">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" alignItems="center" gap={2} m={1}>
            <IconButton aria-label="close" onClick={cancel}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">{title}</Typography>
          </Stack>
          {logo && <img src={logo} alt="Delta" className="logo" />}
        </Stack>
      </Card>
      <Box p={2}>
        {children}
        {!hideControls && (
          <Stack direction="row" gap={2} mt={5}>
            <Button variant="contained" color="primary" size="large" onClick={onSubmit} disabled={isLoading}>{t('save')}</Button>
            <Button variant="outlined" color="secondary" size="large" onClick={cancel}>{t('cancel')}</Button>
          </Stack>
        )}
      </Box>
    </>
  );
}

export default FakeModal;
