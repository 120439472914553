import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, styled, Typography } from '@mui/material';
import { ArchiveIcon } from '../../assets/icons';

interface IProps {
  onClick?: () => void;
  disabled?: boolean;
}

const StyledButton = styled(Button)`
  padding: 6px 8px;
`;

function ArchiveButton({ onClick = () => {}, disabled = false }: IProps) {
  const { t } = useTranslation();

  return (
    <StyledButton variant="outlined" color="secondary" onClick={onClick} disabled={disabled}>
      <ArchiveIcon height={20} width={20} />
      <Typography sx={{ ml: '6px' }}>{t('archive')}</Typography>
    </StyledButton>
  );
}

export default ArchiveButton;
