import React from 'react';
import { DateTimePicker } from '@mui/x-date-pickers';

import { styled } from '@mui/material';
import { getDefaultDate } from 'services/dayjs';
import { Dayjs } from 'dayjs';

interface IProps {
  onChange: (date: any) => void
  value?: string | null | Dayjs
  timezone?: string
  time?: boolean
}

const StyledDatePicker = styled(DateTimePicker)`
  .MuiInputBase-input {
    padding: 9px 14px;
    font-size: 14px;
  }
`;

function CustomDatePicker(
  {
    onChange,
    value = getDefaultDate(),
    timezone = 'Europe/Kyiv',
    time = false,
  }: IProps,
) {
  return (
    <StyledDatePicker
      onChange={onChange}
      value={value}
      views={time ? ['year', 'month', 'day', 'hours', 'minutes'] : ['year', 'month', 'day']}
      timezone={timezone}
      slotProps={{
        layout: {
          sx: {
            ul: {
              '::-webkit-scrollbar': {
                width: '2px',
              },
            },
          },
        },
      }}
    />
  );
}

export default CustomDatePicker;
