import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, CardContent, Grid, Stack, styled, TextField, Typography,
} from '@mui/material';

import { defaultFilters, IMissionsFilterDto } from 'types/mission';
import { ICrew } from 'types/crew';
import { IGetZone } from 'types/zone';
import { colors } from 'theme';
import DatePicker from 'components/datePicker';
import { dayjs } from 'services/dayjs';

import MissionTypeSelector from 'components/missionTypeSelector';
import Autocomplete from 'components/autocomplete';
import DroneIcon from 'assets/icons/drone';
import IconFrame from 'components/iconFrame';
import { useSearchParams } from 'react-router-dom';

interface IProps {
  showTargetsSelect?: boolean
  setShowTargetsSelect?: () => void
  filters?: IMissionsFilterDto
  setFilters?: (filters: any) => void
  crews?: ICrew[]
  zones?: IGetZone[]
  isFiltersVisible: boolean,
  submitFilters: () => void,
}

const FormCardContent = styled(CardContent)(() => ({
  padding: '32px',
  backgroundColor: `${colors.grayscale.b2}`,
  border: `1px solid ${colors.grayscale.b4}`,
  borderRadius: '24px',
}));

const AutocompleteStyled = styled((props: any) => <Autocomplete {...props} />)`
  .MuiInputBase-root {
    min-height: 45px;
  }
  .MuiOutlinedInput-notchedOutline {
    border: ${(props) => (props.error ? `2px solid ${colors.system.red}` : undefined)};
  }
  .MuiOutlinedInput-root {
    padding: 5px;
  }
  .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 7px 14px;
  }
  .MuiChip-root {
    height: 29px;
  }
  .MuiAutocomplete-popper {
    background-color: green;
  }
`;

const getFilterDate = (dateValue: string): string => {
  const convertedDate = dayjs(dateValue)
    .hour(0)
    .minute(0)
    .second(1)
    .format('YYYY-MM-DD HH:mm:ss');

  return convertedDate;
};

function MissionFilters(
  {
    showTargetsSelect = false,
    setFilters = () => {},
    filters = defaultFilters,
    zones = [],
    crews = [],
    isFiltersVisible,
    submitFilters,
  }: IProps,
) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const updateFilter = (updatedFilters: IMissionsFilterDto) => {
    setFilters({ ...updatedFilters, due_date: updatedFilters.due_date ? getFilterDate(updatedFilters.due_date) : '' });
  };

  const updateFiltersWithDate = (value: string) => {
    updateFilter({ ...filters, due_date: value });
  };

  const onSubmitFilters = () => {
    localStorage.setItem('filters', JSON.stringify(filters));
    submitFilters();
  };

  const onResetFilters = () => {
    localStorage.removeItem('filters');
    setFilters(defaultFilters);
    searchParams.set('search', encodeURIComponent(''));
    setSearchParams(searchParams);
    window.location.reload();
  };

  return (
    <Stack>
      {
        isFiltersVisible
        && (
        <FormCardContent style={{ margin: '0 0 10px' }}>
          <Grid container spacing={3} alignItems="center">
            <Grid item md={showTargetsSelect ? 12 : 8} xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={2} alignContent="center">
                  <Typography variant="h3">{t('type')}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <MissionTypeSelector
                    selectedType={filters.type}
                    setSelectedType={(type) => updateFilter({ ...filters, type })}
                    error={null}
                  />
                </Grid>
                <Grid item xs={12} md={2} alignContent="center">
                  <Typography variant="h3">{t('work_zones')}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <AutocompleteStyled
                    multiple
                    id="filters-zone"
                    options={zones}
                    getOptionLabel={(option: IGetZone) => option.name || ''}
                    onChange={(e: any, value: IGetZone[]) => {
                      updateFilter({ ...filters, zones: value });
                    }}
                    value={filters.zones}
                    renderInput={(params: any) => (
                      <TextField
                        placeholder={t('choose_zones')}
                        {...params}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={2} alignContent="center">
                  <Typography variant="h3">{t('due_date')}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <DatePicker
                    value={filters?.due_date ? dayjs(filters?.due_date).local() : null}
                    onChange={updateFiltersWithDate}
                  />
                </Grid>
                <Grid item xs={12} md={2} alignContent="center">
                  <Typography variant="h3">{t('crew')}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <AutocompleteStyled
                    id="mission-crew"
                    options={crews}
                    getOptionLabel={(option: any) => {
                      const selectedCrew = crews?.find((cr) => cr.id === option.id);
                      return `${selectedCrew?.name} • ${selectedCrew?.uav ? selectedCrew?.uav[0]?.name : '-'}`;
                    }}
                    onChange={(e: Event, value: ICrew) => {
                      updateFilter({ ...filters, crew: value });
                    }}
                    value={filters.crew}
                    renderOption={(props: any, option: any) => (
                      <li {...props}>
                        <Stack flexDirection="row" alignItems="center" gap="5px">
                          <IconFrame backgroundColor="#454545"><DroneIcon width="13px" height="13px" /></IconFrame>
                          {option?.name} • {option?.uav ? option?.uav[0]?.name : '-'}
                        </Stack>
                      </li>
                    )}
                    isOptionEqualToValue={(option: ICrew, value: ICrew) => option.id === value.id}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        placeholder={t('select_crew')}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Stack flexDirection="row" alignItems="center" justifyContent="end" gap="5px" mt={3}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => onSubmitFilters()}
              disabled={false}
            >
              {t('save')}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              onClick={() => onResetFilters()}
            >
              {t('cancel')}
            </Button>
          </Stack>
        </FormCardContent>
        )
      }
    </Stack>
  );
}

export default MissionFilters;
