import React from 'react';
import { Button, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { ValidationError } from 'yup';
import { urls } from 'router';

import { useTranslation } from 'react-i18next';
import { IMissionDto } from 'types/mission';
import { getValidationErrors, IError } from 'services/helpers';
import { crewTypes } from 'features/crews/services/constants';

interface Props {
  mission: IMissionDto,
  // eslint-disable-next-line no-unused-vars
  submit: (m: IMissionDto) => void,
  // eslint-disable-next-line no-unused-vars
  setErrors: (errors: IError) => void,
  isPending: boolean,
}

function SubmitMission({
  mission,
  submit,
  setErrors,
  isPending,
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const missionSchema = yup.object({
    name: yup.string().required('name_required'),
    type: yup.string().required('type_required'),
    target: yup.array().when('type', (type, schema) => (type[0] === crewTypes.RECON ? schema : schema.min(1, 'please_select_mission_targets'))),
    zones: yup.array().when('type', (type, schema) => (type[0] === crewTypes.STRIKE ? schema : schema.required('select_zones').min(1, 'select_zones'))),
    nais: yup.array().when('type', (type, schema) => (type[0] === crewTypes.STRIKE ? schema : schema.required('select_nais').min(1, 'select_nais'))),
    crew: yup.object().required('please_select_mission_crew'),
  }).required();

  const validateAndSubmit = (m: IMissionDto, schema: any) => {
    m.nais = m.nais || [];
    schema.validate(m, { abortEarly: false }).then(() => {
      setErrors({});
      submit(mission);
    }).catch((err: ValidationError) => {
      setErrors(getValidationErrors(err));
    });
  };

  return (
    <Stack direction="row" gap={2} mt={2}>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={() => validateAndSubmit(mission, missionSchema)}
        disabled={isPending}
      >
        {t('save')}
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        size="large"
        onClick={() => navigate(urls.missions.landing)}
      >
        {t('cancel')}
      </Button>
    </Stack>
  );
}

export default SubmitMission;
