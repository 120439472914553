import React from 'react';

interface IProps {
  width?: number;
  height?: number;
}

function NAI({ width = 23, height = 23 }: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} preserveAspectRatio="none" version="1.1" viewBox="0.09 0.38 21.84 21.27">
      <g transform="translate(11,11) scale(0.018333333333333333,-0.018333333333333333)">
        <path d="M-501 32l60 -318q4 -122 68 -189l853 134l-249 649q-64 75 -145 77l-374 78zM101 480q114 -15 193 -86l302 -818l-1029 -157h-66l-96 664l257 496zM-389 -86v265h53l112 -178v178h52v-265h-56l-110 171v-171h-51zM120 -87h-60l-23 60h-107l-23 -60h-58l105 266h58zM18 19 l-37 97l-35 -97h72zM144 -86v265h73v-265h-73z" fill="#FFFF00" />
      </g>
    </svg>
  );
}

export default NAI;
