import React from 'react';

interface IProps {
  width?: number;
  height?: number;
}

function ZRK({ width = 23, height = 23 }: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} version="1.2" baseProfile="tiny" viewBox="26 26 148 164">
      <text x="192" y="70" textAnchor="start" fontSize="60" fontFamily="Arial" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round" stroke="#ffffff" fill="none" />
      <g transform="translate(0,172)" strokeWidth="4" stroke="black" fill="none">
        <path d="M 53,1 l 100,0 c15,0 15,15 0,15 l -100,0 c-15,0 -15,-15 0,-15" strokeWidth="44" strokeLinecap="round" strokeLinejoin="round" stroke="none" fill="none" />
      </g>
      <path d="M100,28 L172,100 100,172 28,100 100,28 Z Z" strokeWidth="44" strokeLinecap="round" strokeLinejoin="round" stroke="none" fill="none" />
      <path d="M100,28 L172,100 100,172 28,100 100,28 Z" strokeWidth="4" stroke="black" fill="rgb(255,128,128)" fillOpacity="1" />
      <path d="m 85,140 30,0 c 0,-20 -30,-20 -30,0 z m 15,-15 0,-65 m -15,80 0,-65 c 0,-20 30,-20 30,0 l 0,65" strokeWidth="4" stroke="black" fill="none" />
      <path d="m 85,110 30,0 m -30,-20 30,0 m -30,10 30,0" strokeWidth="4" stroke="black" fill="none" />
      <g transform="translate(0,172)" strokeWidth="4" stroke="black" fill="none">
        <path d="M 53,1 l 100,0 c15,0 15,15 0,15 l -100,0 c-15,0 -15,-15 0,-15" />
      </g>
      <text x="192" y="70" textAnchor="start" fontSize="60" fontFamily="Arial" strokeWidth="4" stroke="none" fill="#000000" />
    </svg>
  );
}

export default ZRK;
