import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
/* eslint-disable-next-line */
import { Button, Drawer, InputAdornment, Stack, styled, TextField, Typography } from '@mui/material';
import { colors } from 'theme';

const IconButton = styled(Button)`
  color: ${colors.grayscale.g2};
  min-width: auto;
  &:hover {
    background-color: transparent;
  }
`;

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    height: 36px;
    background-color: black;
  }
`;

interface INAI {
  id?: string;
  name: string;
  coordinates: string[];
}

interface INAIDrawerProps {
  open: boolean;
  nai: INAI | null;
  onMode: string;
  onClose: () => void;
  saveNai: (nai: INAI) => void;
}

/* eslint-disable-next-line */
function NAIDrawer({ open, nai, onMode, onClose, saveNai }: INAIDrawerProps) {
  const { t } = useTranslation();

  const [coordinates, setCoordinates] = useState<string[]>([]);
  const [name, setName] = useState<string>('');
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    setCoordinates(nai?.coordinates || ['']);
    setName(nai?.name ? nai.name : '');
  }, [nai]);

  const setNaiName = (naiName: string) => {
    setName(naiName);
    setErrors((prevErrors: any) => {
      if (!prevErrors.name) return prevErrors;
      const { name: _name, ...remainingErrors } = prevErrors;
      return remainingErrors;
    });
  };

  const updateCoordinate = (index: number, value: string) => {
    /* eslint-disable-next-line */
    setCoordinates((prevCoordinates) => prevCoordinates.map((coord, i) => (i === index ? value : coord)));
    setErrors((prevErrors: any) => {
      if (!prevErrors.coordinates) return prevErrors;
      const { [index]: _, ...remainingCoordinates } = prevErrors.coordinates;
      return { ...prevErrors, coordinates: remainingCoordinates };
    });
  };

  const addCoordinate = () => {
    setCoordinates((prevCoords) => (Array.isArray(prevCoords) ? [...prevCoords, ''] : ['']));
    setErrors((prevErrors: any) => {
      if (!prevErrors.minCoordinates) return prevErrors;
      const { minCoordinates: _, ...remainingErrors } = prevErrors;
      return remainingErrors;
    });
  };

  const removeCoordinate = (idx: number) => {
    setCoordinates(coordinates.filter((_, index) => index !== idx));
  };

  const resetForm = () => {
    setName('');
    setCoordinates(['']);
  };

  const validateErrors = {
    name: 'enter-nai-name',
    minCoordinates: 'min-3-coordinates-required',
    invalidCoordinate: 'invalid-coordinate',
    emptyCoordinateField: 'empty-coordinate-field',
  };

  const validateForm = () => {
    /* eslint-disable-next-line */
    const newErrors: { name?: string; coordinates?: Record<number, string>; minCoordinates?: string } = {};
    const mgrsRegex = /^(?:\d{1,2}[C-HJ-NP-X])(?:\s?[A-HJ-NP-Z]{2}\s?\d{5}\s?\d{5}|\s?[A-HJ-NP-Z]{2}\d{10})$/;

    if (!name.trim()) {
      newErrors.name = validateErrors.name;
    }

    if (coordinates.length < 3) {
      newErrors.minCoordinates = validateErrors.minCoordinates;
    }

    const coordinateErrors: Record<number, string> = {};
    coordinates.forEach((coordinate, index) => {
      if (!coordinate.trim()) {
        coordinateErrors[index] = validateErrors.emptyCoordinateField;
      } else if (!mgrsRegex.test(coordinate)) {
        coordinateErrors[index] = validateErrors.invalidCoordinate;
      }
    });

    if (Object.keys(coordinateErrors).length > 0) {
      newErrors.coordinates = coordinateErrors;
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const submitForm = () => {
    if (!validateForm()) {
      return;
    }

    const saveData = {
      id: nai?.id,
      name,
      coordinates,
    };

    saveNai(saveData);
    resetForm();
  };

  const closeDrawer = () => {
    onClose();
    setErrors({});
  };

  return (
    <Drawer open={open} anchor="right">
      <Stack sx={{ width: '35vw', zIndex: 1002, padding: '10px' }} gap={1}>
        <Stack direction="row">
          <IconButton onClick={closeDrawer} sx={{ border: 'none' }}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Typography variant="subtitle2">{onMode === 'edit' ? t('edit') : t('add')} NAI</Typography>
        <Typography variant="body2"> {t('name')} NAI </Typography>
        <StyledTextField
          value={name}
          onChange={(e) => setNaiName(e.target.value)}
          placeholder={t('enter-nai-name')}
          error={!!errors.name}
        />
        {coordinates?.map((coordinate: string, index: number) => (
          // eslint-disable-next-line
          <React.Fragment key={index}>
            <Typography variant="body2">
              {t('coordinate-point')} {index + 1}
            </Typography>
            <StyledTextField
              value={coordinate}
              onChange={(e) => updateCoordinate(index, e.target.value)}
              placeholder={t('enter-coordinate')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CloseIcon
                      onClick={() => removeCoordinate(index)}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              error={!!errors.coordinates?.[index]}
            />
          </React.Fragment>
        ))}
        {errors.name && (
          <Stack direction="row" sx={{ paddingLeft: '5px', paddingTop: '5px' }}>
            <Typography variant="caption" color="error">
              {t(validateErrors.name)}
            </Typography>
          </Stack>
        )}
        {/* eslint-disable-next-line */}
        {Object.values(errors.coordinates || {}).find((error) => error === validateErrors.emptyCoordinateField) && (
          <Stack direction="row" sx={{ paddingLeft: '5px', paddingTop: '5px' }}>
            <Typography variant="caption" color="error">
              {t(validateErrors.emptyCoordinateField)}
            </Typography>
          </Stack>
        )}
        {/* eslint-disable-next-line */}
        {Object.values(errors.coordinates || {}).find((error) => error === validateErrors.invalidCoordinate) && (
          <Stack direction="row" sx={{ paddingLeft: '5px', paddingTop: '5px' }}>
            <Typography variant="caption" color="error">
              {t(validateErrors.invalidCoordinate)}
            </Typography>
          </Stack>
        )}
        {errors.minCoordinates && (
          <Stack direction="row" sx={{ paddingLeft: '5px', paddingTop: '5px' }}>
            <Typography variant="caption" color="error">
              {t(validateErrors.minCoordinates)}
            </Typography>
          </Stack>
        )}
        <Stack direction="row" sx={{ alignItems: 'center' }}>
          <IconButton onClick={() => addCoordinate()} sx={{ border: 'none' }} size="small">
            <AddIcon />
            <Typography variant="body2">{t('add-coordinate')}</Typography>
          </IconButton>
        </Stack>
        <Stack direction="row" gap={1}>
          <IconButton
            onClick={() => {
              submitForm();
            }}
            variant="contained"
            color="primary"
          >
            {t('save')}
          </IconButton>
          <IconButton onClick={closeDrawer} variant="outlined" color="secondary">
            {t('cancel')}
          </IconButton>
        </Stack>
      </Stack>
    </Drawer>
  );
}

export default NAIDrawer;
