import React from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import { Button, styled, Typography } from '@mui/material';

interface IProps {
  onClick?: () => void;
  disabled?: boolean;
  hideLabel?: boolean;
}

const KHEditButton = styled(Button)`
  padding: 4px 12px;
`;

function EditButton({ onClick = () => {}, disabled = false, hideLabel = false }: IProps) {
  const { t } = useTranslation();

  return (
    <KHEditButton variant="outlined" color="secondary" onClick={onClick} disabled={disabled}>
      <EditIcon fontSize="small" style={{ paddingRight: hideLabel ? '' : '6px' }} />
      {!hideLabel && <Typography variant="body1">{t('edit')}</Typography>}
    </KHEditButton>
  );
}

export default EditButton;
