import React from 'react';
import {
  Card, CardContent, Stack, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ICrew } from 'types/crew';
import UavTypeIconWithText from 'components/uavTypeIconWithText';
import StatusLabel from '../../../components/statusLabelDropdown';

import './crewCard.css';

interface Props {
  crew: ICrew
  selectCrew: (id: string) => void
  selectedCrewId?: string
}

function CrewCard({ crew, selectCrew, selectedCrewId = '' }: Props) {
  const { t } = useTranslation();

  return (
    <Card onClick={() => selectCrew(crew.id)} variant={selectedCrewId === crew.id ? 'outlined' : 'elevation'}>
      <CardContent sx={{ padding: 2, ':last-child': { padding: 2, cursor: 'pointer' } }}>
        <Stack alignContent="space-between" gap="7px">
          <Stack direction="row" justifyContent="space-between">
            <Typography>{crew.name}</Typography>
            <StatusLabel type="crew" currentStatus={crew.status} id={crew.id} />
          </Stack>
          <Stack direction="row" justifyContent="space-between" flexWrap="wrap" gap={1}>
            <Stack direction="row" gap="6px" alignItems="center">
              {crew.uav?.map((uavItem) => (
                <UavTypeIconWithText key={uavItem.id} name={uavItem.name} type={uavItem.type} />
              ))}
            </Stack>
            <Stack direction="row" gap="6px" alignItems="center">
              {crew.ammo?.map((am) => (
                <UavTypeIconWithText key={am.id} name={`${am.name} · ${t('available')} ${am.quantity} ${t('qnty')}.`} type="ammunition" />
              ))}
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default CrewCard;
