import React from 'react';
import {
  Stack, Tab, Tabs, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
    items: string[];
    selected: string;
    onChange: (value: string) => void;
    hideLabel?: boolean;
}

export default function GroupBy({
  items, selected, onChange, hideLabel = false,
}: Props) {
  const { t } = useTranslation();

  return (
    <Stack direction="row" alignItems="center" mb={1}>
      {!hideLabel && <Typography color="secondary" pr="18px">{t('display')}</Typography>}
      <Tabs value={selected}>
        {items.map((item) => (
          <Tab
            key={item}
            label={t(item)}
            value={item}
            onClick={() => onChange(item)}
          />
        ))}
      </Tabs>
    </Stack>
  );
}
