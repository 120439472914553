import React from 'react';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import {
  Accordion, AccordionDetails, AccordionSummary, Box, Skeleton, Stack, styled, Typography,
} from '@mui/material';

import { KeyboardArrowUp } from '@mui/icons-material';
import { IMission } from 'types/mission';
import { getDayDate } from 'services/dayjs';
import { colors } from 'theme';
import MissionCard from './missionCard';

interface Props {
  name: string
  expanded: boolean
  missions?: IMission[]
  selectMission: (mission: IMission) => void
  selectedMissionId?: string
  isFetching?: boolean
}

const KHAccordion = styled(Accordion)`
  .MuiAccordionSummary-content {
    margin: 20px 0;
  }
  .MuiAccordionDetails-root {
    padding: 0 18px 16px;
  }
  .MuiCardContent-root {
    padding: 8px 15px;
    &:last-child {
      padding: 8px 15px;
    }
  }
`;

function MissionGroup(
  {
    missions = [],
    name,
    expanded,
    selectMission,
    selectedMissionId = '',
    isFetching = false,
  }: Props,
) {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = React.useState<boolean>(expanded);

  React.useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  function filterByDay(missionsList: IMission[] | undefined) {
    return _.groupBy(missionsList, (m) => getDayDate(m.due_date));
  }

  const missionsByDate = filterByDay(missions);

  if (isFetching) return <Skeleton height={50} />;

  return (
    <KHAccordion expanded={isExpanded} onChange={(e, exp) => setIsExpanded(exp)}>
      { (name !== 'finished') ? (
        <AccordionSummary>
          <Box display="flex" justifyContent="center" alignItems="center">
            <KeyboardArrowUp
              style={{
                color: colors.grayscale.g1,
                transform: !isExpanded ? 'rotate(180deg)' : '',
                transition: '300ms ease-out',
              }}
            />
            <Typography variant="h3" pl="15px">{t(name)}</Typography>
            <Typography variant="h3" color={colors.grayscale.g2} pl="10px">{missions?.length || '0'}</Typography>
          </Box>
        </AccordionSummary>
      ) : <Stack mt={2} />}
      <AccordionDetails>
        <Stack gap={1}>
          {Object.keys(missionsByDate).map((date: string) => (
            <div key={date}>
              <Typography variant="body2" mb="4px">{date}</Typography>
              {missionsByDate[date]?.map((mission) => (
                <MissionCard
                  mission={mission}
                  key={mission.id}
                  selectMission={selectMission}
                  selectedMissionId={selectedMissionId}
                />
              ))}
            </div>
          ))}
        </Stack>
      </AccordionDetails>
    </KHAccordion>
  );
}

export default MissionGroup;
