import React from 'react';
import { KeyboardArrowUp } from '@mui/icons-material';
import {
  Accordion, AccordionDetails, AccordionSummary, Box, Skeleton, Stack, styled, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ITarget } from 'types/target';
import { colors } from 'theme';
import TargetCard from './targetCard';

const KHAccordion = styled(Accordion)`
  .MuiAccordionSummary-content {
    margin: 20px 0;
  }
  .MuiAccordionDetails-root {
    padding: 0 18px 16px;
  }
  .MuiCardContent-root {
    padding: 8px 15px;
    &:last-child {
      padding: 8px 15px;
    }
  }
`;

interface Props {
  name: string;
  expanded: boolean;
  targets?: ITarget[];
  selectTarget: (target: ITarget) => void;
  selectedTargetId?: string;
  isFetching?: boolean;
}

function TargetGroup(
  {
    name,
    targets = [],
    expanded,
    selectTarget = () => {},
    selectedTargetId,
    isFetching = false,
  }: Props,
) {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = React.useState(expanded);

  React.useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  if (isFetching) return <Skeleton height={50} />;

  return (
    <KHAccordion expanded={isExpanded} onChange={(e, exp) => setIsExpanded(exp)}>
      <AccordionSummary>
        <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
          <KeyboardArrowUp
            style={{
              color: colors.grayscale.g1,
              transform: !isExpanded ? 'rotate(180deg)' : '',
              transition: '300ms ease-out',
            }}
          />
          <Typography variant="h3">{t(name)}</Typography>
          <Typography variant="h3" color={colors.grayscale.g2}>{targets?.length || '0'}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Stack gap={1}>
          {targets?.map((target: ITarget) => (
            <TargetCard
              key={target.id}
              target={target}
              selectTarget={selectTarget}
              selectedTargetId={selectedTargetId}
              cursor="pointer"
              showMissions
              showStatus={false}
            />
          ))}
        </Stack>
      </AccordionDetails>
    </KHAccordion>
  );
}

export default TargetGroup;
