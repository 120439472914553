import { IMission } from './mission';

export interface INAI {
    id: string
    name: string
    description: string
    zoneId?: string
    zoneName?: string
    coordinates?: string[]
}

export interface IZone {
    id?: string
    name: string
    description?: string
    nais?: INAI[]
}

export interface IGetZone {
    id?: string
    name: string
    description?: string
    nais?: INAI[]
    missions?: IMission[]
}

export interface IGetZoneListResponse {
    pageCount: number
    total: number
    results: IGetZone[]
}

export interface ICreateZoneDto {
    name: string
    description?: string
    nais?: string[]
}

export interface IUpdateZoneDto {
    name: string
    description?: string
}

export const ZoneFormModes = {
  EDIT: 'edit',
  CREATE: 'create',
};
