import React from 'react';
import {
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Autocomplete from 'components/autocomplete';
import { getTextFieldErrorProps, IError } from 'services/helpers';
import { IUAV } from 'types/uav';
import { colors } from 'theme';
import {
  IAmmunition, ICrew, ILocation, IMember,
  IStatus,
} from 'types/crew';
import { role } from 'features/admin/users/services/constants';
import User from '../../admin/users/services/model';
import { Ammunition } from '../../admin/ammunition/services/model';
import { crewStatus } from '../services/constants';

const FormCard = styled(CardContent)(() => ({
  backgroundColor: `${colors.grayscale.b2}`,
  border: 'none',
  padding: '32px 50px',
}));

const AutocompleteStyled = styled((Autocomplete))`
  .MuiInputBase-root {
    min-height: 45px;
  }
  .MuiOutlinedInput-root {
    padding: 5px;
  }
  .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 7px 14px;
  }
  .MuiChip-root {
    height: 29px;
  }
  .MuiAutocomplete-popper {
    background-color: green;
  }
`;

interface Props {
  uavs: IUAV[];
  users: User[];
  locations?: ILocation[];
  ammunitions: Ammunition[];
  crew: ICrew;
  setCrew: (crew: ICrew) => void;
  errors: IError;
}

function CrewForm({
  crew, setCrew, uavs, users, locations = [], ammunitions, errors,
}: Props) {
  const { t } = useTranslation();
  const statuses = Object.keys(crewStatus)
    .map((key) => ({ id: crewStatus[key], name: t(crewStatus[key]) }));
  const updateCrewUav = (uav: IUAV) => {
    if (crew.uav?.find((cruav) => cruav.id === uav.id)) {
      return setCrew({ ...crew, uav: crew.uav.filter((cruav) => cruav.id !== uav.id) });
    }
    return setCrew({ ...crew, uav: [...(crew.uav || []), uav] });
  };

  return (
    <Stack>
      <Card style={{ border: 'none' }}>
        <FormCard>
          <Grid container>
            <Grid item md={12} lg={6}>
              <Grid container spacing={2.5}>
                <Grid item xs={3} alignContent="center">
                  <Typography variant="h3">{t('name')}</Typography>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    size="small"
                    placeholder={t('crew_name')}
                    fullWidth
                    onChange={(e) => setCrew({ ...crew, name: e.target.value })}
                    value={crew?.name}
                    {...getTextFieldErrorProps(errors, 'name', t)}
                  />
                </Grid>
                <Grid item xs={3} alignContent="center">
                  <Typography variant="h3">{t('crewMembers')}</Typography>
                </Grid>
                <Grid item xs={9}>
                  <AutocompleteStyled
                    multiple
                    variant="outlined"
                    id="crew-staff"
                    options={users.filter((user) => user.role.includes(role.CREW_MEMBER))}
                    getOptionLabel={(option: IMember) => option.username || ''}
                    onChange={(e: Event, value: IMember[]) => {
                      setCrew({ ...crew, members: value });
                    }}
                    value={users.filter((user) => crew.members?.find((crm) => crm.id === user.id))}
                    renderInput={(params: any) => (
                      <TextField
                        fullWidth
                        placeholder={t('chooseCrewMembers')}
                        {...params}
                        {...getTextFieldErrorProps(errors, 'members', t)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3} alignContent="center">
                  <Typography variant="h3">{t('uav')}</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Stack direction="row" gap={1} alignItems="center" style={{ flexFlow: 'wrap' }}>
                    {uavs.map((uav) => (
                      <Button
                        key={uav.id}
                        variant={crew.uav?.find((u) => u.id === uav.id) ? 'contained' : 'outlined'}
                        color={crew.uav?.find((u) => u.id === uav.id) ? 'primary' : 'secondary'}
                        sx={{ minWidth: 'auto', padding: '10px 20px' }}
                        onClick={() => updateCrewUav(uav)}
                        size="small"
                      >
                        {uav.name}
                      </Button>
                    ))}
                  </Stack>
                  {!!errors.uav && <Typography color="error" pl={2} variant="caption">{t(errors.uav)}</Typography>}
                </Grid>
                <Grid item xs={3} alignContent="center">
                  <Typography variant="h3">{t('ammunition')}</Typography>
                </Grid>
                <Grid item xs={9}>
                  <AutocompleteStyled
                    multiple
                    id="crew-ammunition"
                    options={ammunitions}
                    getOptionLabel={(option: IAmmunition) => option.name || ''}
                    onChange={(e: any, value: IAmmunition[]) => {
                      setCrew({ ...crew, ammo: value });
                    }}
                    value={ammunitions.filter((ammo) => (
                      crew.ammo?.find((cram) => cram.id === ammo.id)
                    ))}
                    renderInput={(params: any) => (
                      <TextField
                        placeholder={t('chooseAmmunition')}
                        {...params}
                        {...getTextFieldErrorProps(errors, 'ammo', t)}
                      />
                    )}
                  />
                </Grid>
                {locations && (
                  <>
                    <Grid item xs={3} alignContent="center">
                      <Typography variant="h3">{t('crew_location')}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <AutocompleteStyled
                        id="crew-zone"
                        options={locations}
                        getOptionLabel={(option: ILocation) => option.name}
                        isOptionEqualToValue={(option: ILocation, value: ILocation) => (
                          option.id === value.id
                        )}
                        onChange={(e: any, value: ILocation) => {
                          setCrew({ ...crew, location: value });
                        }}
                        value={crew.location}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            placeholder={t('choose_crew_location')}
                            {...getTextFieldErrorProps(errors, 'location', t)}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={3} alignContent="center">
                  <Typography variant="h3">{t('status')}</Typography>
                </Grid>
                <Grid item xs={9}>
                  <AutocompleteStyled
                    id="crew-status"
                    options={statuses}
                    getOptionLabel={(option: IStatus) => option.name}
                    isOptionEqualToValue={(option: IStatus, value: string) => (
                      option.id === value
                    )}
                    onChange={(e: any, value: IStatus) => {
                      setCrew({
                        ...crew,
                        status: value.id || crewStatus.NOT_READY,
                      });
                    }}
                    value={statuses.filter(({ id }) => id === crew.status)[0]}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormCard>
      </Card>
    </Stack>
  );
}

export default CrewForm;
